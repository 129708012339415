/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub";
import {IMPORT_NO_SHOP_PATH, IMPORT_SERVER_ITEM_LINKS_PATH} from '../../../../app/modules/import/core/_models'

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            {/*<AsideMenuItem*/}
            {/*  to='/dashboard'*/}
            {/*  icon='/media/icons/duotune/art/art002.svg'*/}
            {/*  title={intl.formatMessage({id: 'MENU.DASHBOARD'})}*/}
            {/*  fontIcon='bi-app-indicator'*/}
            {/*/>*/}
            <AsideMenuItem
                to='/revaluation'
                icon='/media/icons/duotune/general/gen048.svg'
                title={intl.formatMessage({id: 'MENU.REVALUATION'})}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItemWithSub
                to='/concurency-management'
                icon='/media/icons/duotune/abstract/abs029.svg'
                title={intl.formatMessage({id: 'MENU.CONCURENCY_MANAGEMENT'})}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem
                    to='/concurency-management/companies'
                    icon='/media/icons/duotune/abstract/abs022.svg'
                    title={intl.formatMessage({id: 'MENU.COMPANIES'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/concurency-management/concurency'
                    icon='/media/icons/duotune/abstract/abs045.svg'
                    title={intl.formatMessage({id: 'MENU.CONCURENCY'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/concurency-management/linked-names'
                    icon='/media/icons/duotune/abstract/abs046.svg'
                    title={intl.formatMessage({id: 'MENU.CONCURENCY_LINKED_NAMES'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/concurency-management/groups'
                    icon='/media/icons/duotune/ecommerce/ecm009.svg'
                    title={intl.formatMessage({id: 'MENU.CONCURENCY_GROUPS'})}
                    fontIcon='bi-app-indicator'
                />
                 <AsideMenuItem
                    to='/concurency-management/ignored-items'
                    icon='/media/icons/duotune/general/gen010.svg'
                    title={intl.formatMessage({id: 'MENU.IGNORED_ITEM'})}
                    fontIcon='bi-app-indicator'
                />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/download-management'
                icon='/media/icons/duotune/coding/cod002.svg'
                title={intl.formatMessage({id: 'MENU.DOWNLOAD_MANAGEMENT'})}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem
                    to='/download-management/downloaders'
                    icon='/media/icons/duotune/files/fil017.svg'
                    title={intl.formatMessage({id: 'MENU.DOWNLOADERS'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/download-management/parsers'
                    icon='/media/icons/duotune/general/gen021.svg'
                    title={intl.formatMessage({id: 'MENU.PARSERS'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/download-management/calendar-events'
                    icon='/media/icons/duotune/general/gen014.svg'
                    title={intl.formatMessage({id: 'MENU.CALENDAREVENTS'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/download-management/package-run-info'
                    icon='/media/icons/duotune/general/gen017.svg'
                    title={intl.formatMessage({id: 'MENU.PACKAGE_RUN_INFO'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/download-management/packages'
                    icon='/media/icons/duotune/general/gen017.svg'
                    title={intl.formatMessage({id: 'MENU.PACKAGES'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/download-management/servers'
                    icon='/media/icons/duotune/abstract/abs027.svg'
                    title={intl.formatMessage({id: 'MENU.SERVERS'})}
                    fontIcon='bi-app-indicator'
                />

            </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/product-management'
            icon='/media/icons/duotune/finance/fin006.svg'
            title={intl.formatMessage({id: 'MENU.ITEMS_MANAGEMENT'})}
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem
                to='/items'
                icon='/media/icons/duotune/finance/fin006.svg'
                title={intl.formatMessage({id: 'MENU.ITEMS'})}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              to='/item-server-links'
              icon='/media/icons/duotune/technology/teh001.svg'
              title={intl.formatMessage({id: 'MENU.ITEM_SERVER_LINKS'})}
              fontIcon='bi-app-indicator'
            />
          </AsideMenuItemWithSub>
            <AsideMenuItem
                to='/producers'
                icon='/media/icons/duotune/coding/cod009.svg'
                title={intl.formatMessage({id: 'MENU.PRODUCERS'})}
                fontIcon='bi-app-indicator'
            />

            <AsideMenuItemWithSub
                to='/country-management'
                icon='/media/icons/duotune/maps/map006.svg'
                title={intl.formatMessage({id: 'MENU.COUNTRY_MANAGEMENT'})}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem
                    to='/country-management/currencies'
                    icon='/media/icons/duotune/finance/fin010.svg'
                    title={intl.formatMessage({id: 'MENU.CURRENCIES'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/country-management/countries'
                    icon='/media/icons/duotune/maps/map004.svg'
                    title={intl.formatMessage({id: 'MENU.COUNTRIES'})}
                    fontIcon='bi-app-indicator'
                />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/user-management'
                icon='/media/icons/duotune/general/gen008.svg'
                title={intl.formatMessage({id: 'MENU_USER_MANAGEMENT'})}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem
                    to='/user-management/users'
                    icon='/media/icons/duotune/communication/com006.svg'
                    title={intl.formatMessage({id: 'MENU.USERS'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/user-management/roles'
                    icon='/media/icons/duotune/general/gen019.svg'
                    title={intl.formatMessage({id: 'MENU.ROLES'})}
                    fontIcon='bi-app-indicator'
                />
            </AsideMenuItemWithSub>
            <AsideMenuItem
              to='/reports'
              icon='/media/icons/duotune/general/gen016.svg'
              title={intl.formatMessage({id: 'MENU.REPORTS'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItemWithSub
                to='/imports'
                icon='/media/icons/duotune/abstract/abs029.svg'
                title={intl.formatMessage({id: 'MENU.IMPORTS'})}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem
                    to={`/imports/${IMPORT_NO_SHOP_PATH}`}
                    icon='/media/icons/duotune/general/gen016.svg'
                    title={intl.formatMessage({id: 'MENU.NO_SHOP_IMPORT'})}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to={`/imports/${IMPORT_SERVER_ITEM_LINKS_PATH}`}
                    icon='/media/icons/duotune/general/gen016.svg'
                    title={intl.formatMessage({id: 'MENU.ITEM_SERVER_LINKS_IMPORT'})}
                    fontIcon='bi-app-indicator'
                />
            </AsideMenuItemWithSub>


        </>
    )
}
