import {ID} from "../../../../_metronic/helpers";

export const IMPORT_NO_SHOP_PATH = 'noshop-products'
export const IMPORT_SERVER_ITEM_LINKS_PATH = 'server-item-links'

export interface PROCESS_NO_SHOP_PRODUCTS_REQUEST {
    queue_for_download: boolean,
    import_batch_id: string | null,
    server_ids: ID[],
}

export interface PROCESS_ITEM_SERVER_LINKS_REQUEST {
    import_batch_id: string | null,
}